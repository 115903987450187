export function validateEmail (email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export function getEditorContentLength(htmlString) {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    var supportedTags = ["p", "pre", "blockquote", "h1", "h2", "h3", "h4"];
    var combinedContent = "";
    supportedTags.forEach(function (tagName) {
      var elements = tempDiv.getElementsByTagName(tagName);
      for (var i = 0; i < elements.length; i++) {
        combinedContent += elements[i].textContent || elements[i].innerText;
      }
    });
    var combinedLength = combinedContent.length;
    return combinedLength;
  }